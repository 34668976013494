<template>
  <b-button
    :variant="variant"
    :block="block"
    :size="size"
    :class="buttonClasses.join(' ')"
    @click="processClick"
  >
    <b-spinner
      v-if="loading || forceLoad"
      :small="small"
      type="grow"
      :variant="spinnerVariant"
    />
    <slot v-else />
  </b-button>
</template>

<script>
import {
  BButton,
  BSpinner,
} from 'bootstrap-vue'

export default {
  name: 'SpinnerButton',
  components: {
    BButton,
    BSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    variant: {
      type: String,
      required: false,
      default: '',
    },
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
    spinnerVariant: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: null,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonClasses: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    forceLoad: false,
  }),
  methods: {
    processClick(args) {
      this.$emit('click', args)
    },
    start() {
      this.forceLoad = true
    },
    stop() {
      this.forceLoad = false
    },
  },
}
</script>
